import React from "react";
import "./styles.css";
import { Button } from "../Button";
import { Input } from "../Input";
import LOGO from "../../assets/logo.png";
import axios from "axios";
import { ENDPOINT } from "../../constants/api";
import toastMessage from "../../utils/toastMessage";
import validatePassword from "../../utils/validatePassword";

class ResetPassword extends React.Component {
  state = {
    password: "",
    confirm_password: "",
    otp_code: "",
    error: {},
    username: "",
    isResending: false,
  };

  componentDidMount() {
    this.setState({
      username: this.props.username,
    });
  }

  onChangeText(name, e) {
    let { error, confirm_password, password } = this.state,
      text = e.target.value;

    delete error[name];

    if (name === "password" && text !== "") {
      error.password = validatePassword(text);
    }

    this.setState({
      error,
      [name]: e.target.value,
    });
  }

  validateForm() {
    let { username, error, otp_code, password, confirm_password } = this.state;

    if (otp_code === "") {
      error.otp_code = "OTP code is required";
    }

    if (password === "") {
      error.password = "New password is required";
    } else if (error.password && error.password.type !== "weak") {
      delete error.password;
    }

    if (confirm_password === "") {
      error.confirm_password = "Please confirm password";
    } else if (confirm_password !== password) {
      error.confirm_password = "Password must match";
    }

    this.setState({
      error,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    const { error, username, otp_code, password } = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      const options = {
        method: "POST",
        url: ENDPOINT + "/authentication_forgotcred",
        data: {
          email: this.props.username,
          passcode: otp_code,
          password,
        },
      };

      axios(options)
        .then((res) => {
          this.setState({
            isSubmitting: false,
          });

          window.location.href = "/login";
        })
        .catch((error) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage("error", error);
        });
    }
  };

  onResendOTP() {
    const { username } = this.state;

    this.setState({
      isResending: true,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/forgot_password_user",
      data: {
        email: username,
      },
    };

    axios(options)
      .then((res) => {
        this.setState({
          isResending: false,
          otp_code: "",
        });

        toastMessage("success", "New OTP was sent to " + username);
      })
      .catch((error) => {
        this.setState({
          isResending: false,
        });

        toastMessage("error", error);
      });
  }

  render() {
    let masked_username = "",
      star_username = "",
      { username } = this.props;

    if (username && username !== "") {
      let name = username.split("@")[0];
      let half_text = name.slice(0, name.length / 2);

      for (let i = 0; i < half_text.length; i++) {
        star_username += "*";
      }

      masked_username = star_username + "" + username.slice(half_text.length);
    }

    return (
      <div className="auth-form-container">
        <div className="container">
          <div
            className="row align-items-center justify-content-center"
            style={{ height: "100vh" }}
          >
            <center className="form-logo-container">
              <img src={LOGO} />
              <span>
                Clean<b>Kigali</b>
              </span>
            </center>
            <div className="col-12 col-md-5">
              <div className="form-container card form-card">
                <div className="card-body">
                  <h3>
                    <b>Reset Password</b>
                  </h3>
                  <p className="text-disabled mb-4">
                    Enter OTP code sent to <b>{masked_username}</b>
                  </p>
                  <form>
                    <Input
                      value={this.state.otp_code}
                      onChange={(e) => this.onChangeText("otp_code", e)}
                      type="number"
                      required
                      placeholder="Enter OTP Code (6 digits)"
                      className="form-control-lg "
                      error={this.state.error.otp_code}
                    />
                    <Input
                      value={this.state.password}
                      onChange={(e) => this.onChangeText("password", e)}
                      type="password"
                      required
                      placeholder="New password"
                      className="form-control-lg "
                      error={this.state.error.password}
                    />
                    <Input
                      value={this.state.confirm_password}
                      onChange={(e) => this.onChangeText("confirm_password", e)}
                      type="password"
                      required
                      placeholder="Retype password"
                      className="form-control-lg"
                      error={this.state.error.confirm_password}
                    />
                    <Button
                      text="Submit"
                      className="btn-lg btn-primary w-100"
                      isSubmitting={this.state.isSubmitting}
                      onPress={this.onSubmit.bind(this)}
                    />
                  </form>
                </div>
                <center className="card-footer">
                  <a
                    href="#"
                    onClick={
                      !this.state.isResending
                        ? this.onResendOTP.bind(this)
                        : null
                    }
                    className={`${this.state.isResending && "text-disabled"}`}
                  >
                    <b>
                      {this.state.isResending ? "Resending..." : "Resend OTP"}
                    </b>
                  </a>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
